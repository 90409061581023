export const LANDING = '/';
export const LEGAL = '/nota-legal';
export const PRIVACY = '/politica-de-privacidad';
export const COOKIES = '/cookies';

export const REFORMAS = '/reformas';
export const BANOS = '/reformas/reformas-banos-en-barcelona';
export const COCINAS = '/reformas/reformas-cocinas-en-barcelona';
export const LOCALES = '/reformas/reformas-locales-barcelona';
export const PISOS = '/reformas/reformas-pisos-en-barcelona';
export const ELECTRICISTAS = '/reformas/electricistas-barcelona';
export const FONTANEROS = '/reformas/fontaneros-barcelona';

export const CONSTRUCCION = '/construccion-de-viviendas';
export const CONTENEDORES = '/construccion-de-viviendas/casas-con-contenedores-maritimos';
export const CASA_CONTENEDOR = (slug) => `/construccion-de-viviendas/casas-con-contenedores-maritimos/${slug}`;
export const CARGADORES = '/instalaciones/cargadores-coches-electricos';
export const CARGADOR = (slug) => `/instalaciones/cargadores-coches-electricos/${slug}`;
export const PRESUPUESTO = '/presupuesto';